import {useState} from "react";
// const { JSDOM } = require( "jsdom" );
// const { window } = new JSDOM( "" );
const $ = require( "jquery" )( window );


export function ContactUs() {

  const [formSubmission , setFormSubmission] = useState({
    name: "",
    email: "",
    subject: "",
    reason: "I'd like to learn about Betaque Services",
    message: ""
  });

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormSubmission({...formSubmission,  [name]: value});
  }

  const submitForm = (e) => {
    e.preventDefault();
    const newRecord = { ...formSubmission };
    newRecord.value = '';
    sendMail(newRecord);
  }

  function sendMail(newRecord){
    document.getElementsByClassName("load-circ")[0].className=("load-circle");
    document.getElementsByClassName("f-over")[0].className=('f-overlay');
    document.getElementsByClassName("loading-t")[0].className=('loading-text-i');
    window.Email.send({
      Host: "smtp.gmail.com",
      Username: "sendmailtoBetaque@gmail.com",
      Password: "dgmsrbdfrthytthh",
      To: 'info@betaque.com',
      From: `${newRecord.email}`,
      Subject: `${newRecord.name} sent you a message: ${newRecord.subject}`,
      Body: `Name: ${newRecord.name} <br/> Email: ${newRecord.email} <br/> Reason: ${newRecord.reason} <br/> Message: ${newRecord.message}`,
    }).then((message) => {
      
      return (
        document.getElementsByClassName("alert-s")[0].className=('alert alert-s-m'),

        document.getElementsByClassName("loading-text-i")[0].className=('loading-t'),
        document.getElementsByClassName("load-circle")[0].className=('load-circ'),
        setTimeout(() => {
          document.getElementsByClassName("f-overlay")[0].className=('f-over')
          document.getElementsByClassName("alert alert-s-m")[0].className=('alert-s')
        },3000)
      );
    }).catch((err) => {
      return(
        setTimeout(() => {
          document.getElementsByClassName("f-over")[0].className=('f-overlay')
          document.getElementsByClassName("alert-f")[0].className=('alert alert-f-m')
          },3000)
    )
  })
}

  




    return <section className="contact" id="contact">
    <div className="container">
      <div className="c-title">
        <h1 className="heading"><span>Contact</span> Us </h1>
      </div>
      <form className="contact-form" id="contact-form" onSubmit={submitForm}>
        <div className="form-row">
          <div className="form-group col-6">
            <label htmlfor="name">Name</label>
            <input type="text" className="form-control" id="inputName" placeholder="Your Name*" onChange={handleInput} value={formSubmission.name} name="name" maxLength="25"
              required/>
          </div>
          <div className="form-group col-6">
            <label htmlfor="email">Email Address</label>
            <input type="email" className="form-control" id="inputEmail" placeholder="Your Mail Id*" onChange={handleInput} value={formSubmission.email} name="email" required/>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-6">
            <label htmlfor="subject">Subject</label>
            <input type="text" className="form-control" id="inputSub" value={formSubmission.subject} onChange={handleInput} name="subject" placeholder="Your Subject"
              maxLength="65" required/>
          </div>
          <div className="form-group col-6">
            <label htmlfor="select-reason">Select a Reason</label>
            <select  id="reason" value={formSubmission.reason} onChange={handleInput} className="form-control" name="reason">
              <option value="I'd like to learn about Betaque Services" >I'd like to learn about Betaque Services</option>
              <option value="I'm interested in speaking with Experts">I'm interested in speaking with Experts</option>
              <option value="I'll like to know Your Pricing Model.">I'll like to know Your Pricing Model.</option>
            </select>
          </div>
        </div>
        <div className="form-group">
          <label htmlfor="msg">Message <span className="msg-muted">(Max-Length: 600)</span> </label>
          <textarea className="form-control" id="f-message" rows="3" placeholder="Your Message..." onChange={handleInput} value={formSubmission.message} name="message"
            maxLength="600" required></textarea>
        </div>
        <button type="submit" id="f-submit" onClick={submitForm}>Send Message</button>
      </form>
      <div className="f-over"></div>
      <div id="loading">
        <div className="load-circ" style={{display: "none"}}>
          <span className="one"></span>
        </div>
      </div>
      {/* <div className="spinner-border text-light spinner" style={{display: "none"}} role="status"></div> */}
      <p className="loading-t" style={{display: "none"}}>Please Wait! We are sending Your Request To Our Betaque Server!!!
      </p>
      <div className="alert alert-s" style={{display: "none"}}>
        <h2>Congratulations!!</h2>
        <p>We Have Received Your Message, Our Team Will Get Back To You Soon!</p>
      </div>
      <div className="alert alert-f" style={{display: "none"}}>
        <h2>Sorry!!</h2>
        <p>Some Error Occured, Please Try Again In a While!</p>
      </div>
      <div className={`alert alert-success alert-dismissible fade show`} style={{display: "none"}} role="alert">
        <strong>Congratulations!</strong> Your Form has been submitted Our Team will Get Back to You Soon!!
        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>
      <div className="alert alert-danger alert-dismissible fade show" style={{display: "none"}} role="alert">
        <strong>Error!</strong> You should check in on some of those fields below.
        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>

    </div>
  </section>
}