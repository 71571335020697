import { publicPath } from "../config"
export function WhyChoose() {
  return <section className="new-element">
    <div className="blue-block">
    </div>
    <div className="why-choose-index">
      <div className="whychoose-container">
        <div className="whychoose-row">
          <div className="whychoose-col-1">
            <div className="whychoose-heading">
              <h2>Generate Competitive Advantage from Cloud with Betaque</h2>
            </div>
            <div className="whychoose-content">
              <p>We help Small, Medium & Large Organizations to achieve their Goals in Development and Operations in minimal time without compromising the industry standards. We support them to architect, implement and manage their Infrastructure. Also, we provide other DevOps services. </p>
              <p><strong>Key Principles:</strong> Create Highly Secure, Scalable, Reliable, Available Infrastructure with faster release channels. We believe that DevOps is harnessing integration, interaction, and innovation. </p>
            </div>
            <div className="whychoose-btn">
              <a href="/whyus">Why Choose Us</a>
            </div>
          </div>
          <div className="whychoose-col-2">
            <img src={publicPath + '/img/codeYaan-hero.svg'} alt="" />
          </div>
        </div>
      </div>
    </div>

  </section>
}