export function Footer() {
    return <footer className="footer">
    <div className="container-fluid">
      <div className="f-row">
        <div className="p1">
          <h1>
            <a href="/">β</a>
          </h1>
          <br />
        </div>
        <div className="nav-part">
          <div className="f-head"><a href="/services">Services</a></div>
          <div className="f-list f-service">
            <p><a href="/services#serv-d">Infrastructure Monitoring</a></p>
            <p><a href="/services#serv-c">Kubernetes Migration</a></p>
            <p><a href="/services#serv-b">Cloud Migration</a></p>
            <p><a href="/services#serv-a">Cloud Architecture and Implementation</a></p>
            <p><a href="/services#serv-e">DevOps Services</a></p>
            <p><a href="/services#serv-g">Web Development</a></p>
          </div>
        </div>
        <div className="nav-part">
          <div className="f-head"><a href="/">Company</a></div>
          <div className="f-list">
            <p><a href="/about">About</a></p>
            <p><a href="/careers">Careers</a></p>
            <p><a href="/whyus">Why Us</a></p>
          </div>
          
        </div>
        <div className="nav-part">
          <div className="f-head"><a href="/#contact">Contact</a></div>
          <div className="f-list">
            <a href="mailto:info@betaque.com">info@betaque.com</a>
            <p className="f-add">6A, 6th Floor, Central Mall, TI Next, RNT Marg, Indore, Madhya Pradesh 452001</p>
          </div>
          
        </div>
        <div className="media-logo">
          <a href="https://www.linkedin.com/company/Betaque/"><i className="fab fa-linkedin-in"></i></a>
          <a href="https://www.instagram.com/betaquesolutions/?hl=en"><i className="fab fa-instagram"></i></a>
          <a href="https://twitter.com/betaque_"><i className="fab fa-twitter"></i></a>
      </div>
      </div>
      <div className="footer-text">Copyright © 2022 Betaque Inc. All Rights Reserved</div>
      <div className="footer-block"><a href="/privacypolicy">Privacy & Policy</a></div>
    </div>
  </footer>
}