import { Navbar } from "../Components/Navbar";
import { Footer } from "../Components/Footer";
import { publicPath } from "../config";

export default function About() {
  return (
    <>
      <Navbar />
      <header className="header">
        <div className="about-sec-1">
          <div className="container">
            <h3>
              <strong>ABOUT US</strong>
            </h3>
            <div className="about-header">
              <h2>
                We solve complex business challenges by building
                <br /> <strong>Cloud </strong>
                and <strong>DevOps</strong> products, tools, & services.
              </h2>
            </div>
            <div className="about-content">
              <div className="row">
                <div className="col-8 about-left">
                  <div className="about-heading">
                    <p>
                      <strong>Who</strong>
                      <span> We Are?</span>
                    </p>
                  </div>
                  <div className="about-p">
                    <p>
                      We are Partners of Your Cloud Journey. We help all types
                      of Organizations to architect, implement and manage their
                      Infrastructure. Also, provides other DevOps services.
                    </p>
                    <br />
                    <p>
                      We believe that DevOps is harnessing integration,
                      interaction, and innovation. We provide all types of
                      DevOps Services and ensure the DevOps Culture of your
                      company.{" "}
                    </p>
                    <br />
                    <p>
                      We Aim to provide Certified Experts of the domain to our
                      Clients, So they can focus on building core features
                      instead of hiring, training, and managing the team.{" "}
                    </p>
                    <br />
                  </div>
                </div>
                <div className="col-4 img">
                  <img
                    src={`${publicPath}/img/about-head.gif`}
                    className="img-responsive animated"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="about-sec-4">
        <div className="container">
          <div className="about-content">
            <div className="row">
              <div className="col-4 img">
                <img
                  src={`${publicPath}/img/about-2.gif`}
                  className="img-responsive animated"
                  alt=""
                />
              </div>
              <div className="col-8 about-right">
                <div className="about-heading">
                  <p>
                    <strong>Our </strong>
                    <span>Story</span>
                  </p>
                </div>
                <div className="about-p">
                  <p>
                    Betaque was founded with an idea to provide easy, fast, and
                    best in class Cloud and DevOps service with certified
                    experts of the domain. So that the companies can focus on
                    building features.
                  </p>
                  <br />
                  <p>
                  We serve multiple clients of different domains like BlockChain, AI/ML, Gaming, Ecommerce, and others.
                  </p>
                  <br />
                  <p>
                  Our Growing team is based in India and serves companies across the globe.
                  </p>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
