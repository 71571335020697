export function Navbar (){
    return <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top scrolling-active">
    <div className="container-fluid" >
      <a className="navbar-brand" href="/"><span><span className="beta">β</span><span className="eta">ETA</span></span><span
          className="q">Q</span><span className="ue">UE</span></a>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav n-middle">
          <li className="nav-item">
            <a className="nav-link" aria-current="page" href="/">Home</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="../Services">Services</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="../WhyUs">Why Us</a>
          </li>
          
          <li className="nav-item">
            <a className="nav-link" href="../Careers">Careers</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="../About">About</a>
          </li>
        </ul>
          <li className="nav-item n-button">
            <a className="nav-link" href="/#contact">Contact</a>
          </li>
      </div>
    </div>
  </nav>
}