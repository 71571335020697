import { Navbar } from "../Components/Navbar";
import { Footer } from "../Components/Footer";

export default function CloudCareer() {
  return (
    <>
      <Navbar />

      <header className="header">
        <div className="c-temp">
          <div className="container">
            <a href="/careers">
              <h3>Open Position</h3>
            </a>
            <hr />
            <p className="position-name">Cloud Engineer(Indore)</p>
            <div className="career-info">
              <div className="career-desc">
                <h4>Description</h4>
                <p>
                  Betaque is looking for a Cloud Engineer who possesses a
                  balance of technical proficiency, business knowledge, with
                  strong communication skills.
                </p>
                <p>
                  The Cloud Engineer will work closely with customers (other IT
                  team and business lines) to understand their technology and
                  business needs, and then design scalable, highly-available,
                  and resilient cloud solutions based on these business needs.
                </p>
                <p>
                  The Cloud Engineer will work closely with other team members
                  to implement these solutions, this may include Cloud
                  Architect, Cloud Engineers, DevSecOps Engineers and Security
                  Architects, as needed for implementation of Cloud services and
                  solutions.
                </p>
              </div>
              <div className="career-tech">
                <h4>Tech Stack:-</h4>
                <ul>
                  <li>AWS/GCP/Azure, Terraform.</li>
                </ul>
              </div>
              <div className="career-reqmt">
                <h4>Requirements</h4>
                <ul>
                  <li>
                    Must have expertise in any one CLoud Platform AWS/GCP/Azure.
                  </li>
                  <li>Good knowledge of VPC and networking</li>
                  <li>
                    Networking services( VPC, Firewalls, Load balancers),
                    Storage service (GCS, S3, disk, images) , Database (SQL and
                    NoSQL), All Computer services, Serverless , etc
                  </li>
                  <li>
                    Worked with managed services like Queue system
                    (SQS/rabbitmq/Pub-Sub)(Optional)
                  </li>
                  <li>
                    Should have knowledge of Infrastructure as a Code tool like
                    Terraform.
                  </li>
                </ul>
              </div>
              <div className="career-benefits">
                <h4>
                  <a href="/careers#careers-sec-2">
                    Know Your Perks <span>Here</span>
                  </a>
                </h4>
              </div>
            </div>
            <a href="mailto:careers@betaque.com" className="job-apply">
              Apply
            </a>
          </div>
        </div>
      </header>
      <Footer />
    </>
  );
}
