import { Navbar } from "../Components/Navbar";
import { Footer } from "../Components/Footer";

export default function NodeDeveloper() {
  return (
    <>
      <Navbar />

      <header className="header">
        <div className="c-temp">
          <div className="container">
            <a href="/careers">
              <h3>Open Position</h3>
            </a>
            <hr />
            <p className="position-name">Node JS Developer(Indore)</p>
            <div className="career-info">
              <div className="career-desc">
                <h4>Description</h4>
                <p>
                We are looking for a Node.js Developer responsible for managing data interchange between the server and the users. Your primary focus will be the development of all server-side logic, definition, and maintenance of the central database and ensuring high performance and responsiveness to requests from the front-end. You will also be responsible for integrating the front-end elements built by your co-workers into the application. Therefore, a basic understanding of front-end technologies is necessary as well.
                </p>
              </div>
              <div className="career-tech">
                <h4>Tech Stack:-</h4>
                <ul>
                  <li>Node, Javascript</li>
                </ul>
              </div>
              <div className="career-reqmt">
                <h4>Requirements</h4>
                <ul>
                  <li>2+ years of working experience.</li>
                  <li>In-depth knowledge of Node.js and frameworks (such as Express).</li>
                  <li>Strong proficiency with JavaScript.</li>
                  <li>Understanding the nature of asynchronous programming and its quirks and workarounds</li>
                  <li>Good understanding of server-side templating languages and CSS preprocessors.</li>
                  <li>Knowledge of Web Services and REST</li>
                  <li>Good knowledge of OOPS and data structure concepts.</li>
                  <li>Should know UI technologies with AJAX, jQuery, and JavaScript.</li>
                  <li>Proficient understanding of code versioning tools, such as Git.</li>
                  <li>Must have strong communication and presentation skills</li>
                  <li>BE/BTech. in Computer Science or other related fields of study or applicable work experience</li>
                </ul>
              </div>
              <div className="career-benefits">
                <h4>
                  <a href="/careers#careers-sec-2">
                    Know Your Perks <span>Here</span>
                  </a>
                </h4>
              </div>
            </div>
            <a href="mailto:careers@betaque.com" className="job-apply">
              Apply
            </a>
          </div>
        </div>
      </header>
      <Footer />
    </>
  );
}
