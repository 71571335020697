import { Navbar } from "../Components/Navbar";
import { Footer } from "../Components/Footer";

export default function MERNDeveloper() {
  return (
    <>
      <Navbar />

      <header className="header">
        <div className="c-temp">
          <div className="container">
            <a href="/careers">
              <h3>Open Position</h3>
            </a>
            <hr />
            <p className="position-name">MERN Full Stack Developer (Indore)</p>
            <div className="career-info">
              <div className="career-desc">
                <h4>Description</h4>
                <p>
                We are looking for a Full Stack Developer to produce scalable software solutions. As a Full Stack Developer, you should be proficient with front-end and back-end coding languages, development frameworks and third-party libraries. You should also be a team player with a knack for visual design and utility. It will be great if you are familiar with Agile methodologies.
                </p>
                
              </div>
              <div className="career-tech">
                <h4>Tech Stack:-</h4>
                <ul>
                  <li>MongoDB, ReactJS, Node JS , ExpressJS/NestJS/Fastify, CSS</li>
                </ul>
              </div>
              <div className="career-reqmt">
                <h4>Job Responsibilities</h4>
                <ul>
                <li>Execute software solutions by employing creative and non-conventional approaches to build solutions or resolve technical problems.</li>
                <li>Develop secure and high-quality production code while maintaining synchronous algorithms that integrate with relevant systems.</li>
                <li>Create architecture and design artifacts for complex applications, ensuring that software code development meets design constraints.</li>
                <li>Gather, analyze, synthesize, and visualize data from diverse and extensive datasets to continuously improve software applications and systems.</li>
                <li>Proactively identify hidden problems and patterns in data, leveraging insights to enhance coding practices and system architecture.</li>
                <li>Contribute to software engineering communities of practice and participate in events exploring new and emerging technologies.</li>
                </ul>
              </div>
              <div className="career-reqmt">
                <h4>Requirements</h4>
                <ul>
                  <li>2+ years of web development experience, specifically in Core Java, HTML, CSS, JavaScript, ReactJS, and NodeJS.</li>
                  <li>In-depth knowledge and working experience on Restful API.</li>
                  <li>In-depth knowledge of Typescript, ReactJS, Redux and Hooks.</li>
                  <li>Knowledge of Figma would be plus.</li>
                  <li>Knowledge of Angular would be plus.</li>
                  <li>Proficiency with fundamental front-end languages such as HTML, JavaScript and any CSS framework like Bootstrap or Tailwind.</li>
                  <li>Working knowledge of NoSQL/SQL technologies.</li>
                  <li>Understanding of caching and third-party integration.</li>
                  <li>Proficient in coding with one or more programming languages.</li>
                  <li>Hands-on practical experience in system design, application development, testing, and ensuring operational stability.</li>
                  <li>Proficiency in developing, debugging, and maintaining code within a large corporate environment, using modern programming languages and database querying languages.</li>
                  <li>Overall understanding of the Software Development Life Cycle.</li>
                </ul>
              </div>
              <div className="career-benefits">
                <h4>
                  <a href="/careers#careers-sec-2">
                    Know Your Perks <span>Here</span>
                  </a>
                </h4>
              </div>
            </div>
            <a href="mailto:careers@betaque.com" className="job-apply">
              Apply
            </a>
          </div>
        </div>
      </header>
      <Footer />
    </>
  );
}
